import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/api';

const UserDropdown = () => {
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [userNickname, setUserNickname] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const currentUserCookie = getCookie('currentUser');
    if (currentUserCookie) {
      const user = JSON.parse(currentUserCookie);
    if (user) {
      if(user.role === "teacher") { setUserRole('/teacher'); }
      if(user.role === "student") { setUserRole('/student'); }
    }
      setUserNickname(user.nickname);
      setIsLoggedIn(true);
    }
  }, []);

  const handleUserDropdownToggle = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const logoutUser = async () => {
    try {
      // Perform logout action
      const response = await logout();

      // Remove user information from cookies
      document.cookie = 'currentUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      setIsLoggedIn(false);
      sessionStorage.clear();
      // Redirect user to the login page
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Function to get cookie value by name
  const getCookie = (name) => {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : null;
  };

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        onClick={handleUserDropdownToggle}
        className="px-6 py-4 text-white text-center inline-flex items-center font-bold rounded"
      >
        {userNickname}
        <svg
          className="w-2.5 h-2.5 ml-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      {userDropdownOpen && (
        <div className="z-10 absolute right-0 mt-2 w-44 bg-darker divide-y divide-gray-600 rounded-md shadow">
          <ul className="py-2 text-sm font-semibold text-gray-200">
            <li>
              <button
                type="button"
                onClick={() => navigate(userRole+'/user')}
                className="flex items-center  px-4 py-2 w-full text-left hover:bg-lav"
              >
                <svg
                  className="w-4 h-4 mr-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
                </svg>
                User Info
              </button>
            </li>
            <li>
              <div className="border-t border-gray-600"></div>
              <button
                type="button"
                onClick={() => logoutUser()}
                className="flex font-semibold items-center px-4 py-2 w-full text-left hover:bg-lav"
              >
                <svg
                  className="w-4 h-4 mr-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M10 16v-3H3v-2h7V8l5 4-5 4zm-1 3h8V5H9v4H7V5c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H9c-1.1 0-2-.9-2-2v-4h2v4z"/>
                </svg>
                Log Out
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
