import React, { useState, useEffect } from 'react';
import { getStudentsByGroupId, addStudent, deleteStudent } from '../services/api';
import { useParams } from 'react-router-dom';

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [newStudentName, setNewStudentName] = useState('');
  const [newStudentEmail, setNewStudentEmail] = useState('');
  const { id: groupId } = useParams();

  useEffect(() => {
    fetchStudents();
  }, [groupId]); // Fetch students whenever groupId changes

  const fetchStudents = async () => {
    try {
      const data = await getStudentsByGroupId(groupId);
      setStudents(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 bg-lighter text-white">
      <h1 className="text-2xl font-bold mb-4">Список студентів цієї групи</h1>
      <table className="w-2/5">
        <thead>
          <tr className="bg-darker-3">
            <th className="text-left py-2 px-4">Назва</th>
            <th className="text-left py-2 px-2">Email</th>
          </tr>
        </thead>
        <tbody>
          {students.map(student => (
            <tr key={student._id} className="border-b border-gray-700">
              <td className="text-left py-2 px-4 bg-darker">{student.name}</td>
              <td className="text-left py-2 px-2 bg-darker">{student.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentList;


