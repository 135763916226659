import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getSubjects,
  deleteSubject,
  addSubject,
  editSubjectName,
  editSubjectStatus,
  editSubjectPublic,
} from "../services/api";

const SubjectList = () => {
  const [subjects, setSubjects] = useState([]);
  const [newSubjectName, setNewSubjectName] = useState("");
  const [editingSubjectId, setEditingSubjectId] = useState("");
  const [editingSubjectName, setEditingSubjectName] = useState("");

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const data = await getSubjects();
        setSubjects(data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };

    fetchSubjects();
  }, []);

  const handleEditSubject = (subjectId, subjectName) => {
    setEditingSubjectId(subjectId);
    setEditingSubjectName(subjectName);
  };

  const handleSaveSubject = async (subjectId, e) => {
    e.preventDefault();
    try {
      await editSubjectName(subjectId, editingSubjectName);
      const updatedSubjects = subjects.map((subject) => {
        if (subject._id === subjectId) {
          return { ...subject, name: editingSubjectName };
        }
        return subject;
      });
      setSubjects(updatedSubjects);
      setEditingSubjectId("");
    } catch (error) {
      console.error("Error saving subject:", error);
    }
  };

  const handleDeleteSubject = async (subjectId, e) => {
    e.preventDefault();
    try {
      await deleteSubject(subjectId);
      const updatedSubjects = subjects.filter(
        (subject) => subject._id !== subjectId
      );
      setSubjects(updatedSubjects);
    } catch (error) {
      console.error("Error deleting subject:", error);
    }
  };

  const handleAddSubject = async (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение отправки формы
    try {
      const newSubject = await addSubject(newSubjectName);
      console.log(newSubject);
      setSubjects([...subjects, newSubject]);
      setNewSubjectName("");
    } catch (error) {
      console.error("Error adding subject:", error);
    }
  };

  const handleToggleAcceptsGrades = async (subjectId, currentStatus) => {
    try {
      await editSubjectStatus(subjectId, !currentStatus);
      const updatedSubjects = subjects.map((subject) => {
        if (subject._id === subjectId) {
          return { ...subject, acceptsGrades: !currentStatus };
        }
        return subject;
      });
      setSubjects(updatedSubjects);
    } catch (error) {
      console.error("Error toggling acceptsGrades:", error);
    }
  };

  const handleTogglePublicRating = async (subjectId, currentStatus) => {
    try {
      await editSubjectPublic(subjectId, !currentStatus);
      const updatedSubjects = subjects.map((subject) => {
        if (subject._id === subjectId) {
          return { ...subject, publicRating: !currentStatus };
        }
        return subject;
      });
      setSubjects(updatedSubjects);
    } catch (error) {
      console.error("Error toggling publicRating:", error);
    }
  };

  return (
    <div className="p-6 bg-lighter text-white">
      <h1 className="text-2xl font-bold mb-4">Список предметів</h1>
      <form onSubmit={handleAddSubject} className="mb-4 flex flex-row gap-3">
        <input
          type="text"
          value={newSubjectName}
          onChange={(e) => setNewSubjectName(e.target.value)}
          placeholder="Введіть назву предмета"
          className="bg-lav rounded placeholder-gray-400"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Додати предмет
        </button>
      </form>
      <table className="w-fit">
        <thead>
          <tr className="bg-darker-3">
            <th className="text-left py-2 px-4">Назва</th>
            <th className="text-left py-2 px-4">Приймає оцінки</th>
            <th className="text-left py-2 px-4">Публічний рейтинг</th>
            <th className="text-left py-2 px-2">Дії</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map((subject) => (
            <tr key={subject._id} className="border-b border-gray-700">
              <td className="py-2 px-4 bg-darker">
                {editingSubjectId === subject._id ? (
                  <input
                    type="text"
                    value={editingSubjectName}
                    onChange={(e) => setEditingSubjectName(e.target.value)}
                    className="p-2 bg-darker text-white"
                  />
                ) : (
                  <span>{subject.name}</span>
                )}
              </td>
              <td className="py-2 px-4 bg-darker">
                <input
                  type="checkbox"
                  checked={subject.acceptsGrades}
                  onChange={() =>
                    handleToggleAcceptsGrades(
                      subject._id,
                      subject.acceptsGrades
                    )
                  }
                />
              </td>
              <td className="py-2 px-4 bg-darker">
                <input
                  type="checkbox"
                  checked={subject.publicRating}
                  onChange={() =>
                    handleTogglePublicRating(subject._id, subject.publicRating)
                  }
                />
              </td>
              <td className="py-2 px-4 bg-darker">
                {editingSubjectId === subject._id ? (
                  <button
                    onClick={(e) => handleSaveSubject(subject._id, e)}
                    className="bg-green-500 text-white py-2 px-4 rounded mr-2"
                  >
                    OK
                  </button>
                ) : (
                  <button
                    onClick={() => handleEditSubject(subject._id, subject.name)}
                    className="bg-orange-500 text-white py-2 px-4 rounded mr-2"
                  >
                    Редагувати
                  </button>
                )}
                <button
                  onClick={(e) => handleDeleteSubject(subject._id, e)}
                  className="bg-red-500 text-white py-2 px-4 rounded"
                >
                  Видалити
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubjectList;
