import React, { useState, useEffect } from 'react';

const UserInfo = () => {
  const [user, setUser] = useState(null);
  const getCookie = (name) => {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : null;
  };
  useEffect(() => {
    // Отримуємо дані користувача з localStorage
    const currentUser= getCookie('currentUser');
    if (currentUser) {
      setUser(JSON.parse(currentUser));
    }
  }, []);

  return (
    <div className="bg-lighter min-h-screen flex flex-col justify-center items-center">
      <div className="max-w-md w-full bg-darker shadow-lg rounded-md p-6">
        {user ? (
          <div>
            <h1 className="text-2xl font-bold mb-4">Інформація про користувача</h1>
            <p><strong>Ім'я:</strong> {user.nickname}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Роль:</strong> {user.role}</p>
          </div>
        ) : (
          <p className="text-red-500 mb-4">Інформація про користувача не знайдена. Будь ласка, увійдіть.</p>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
