import React from "react";
import StudentCard from "./StudentCard";

const TopStudentsList = ({ students }) => {
  // Функція для обчислення середньої оцінки
  const calculateAverageGrade = (grades) => {
    if (grades.length === 0) return 0;
    const sum = grades.reduce((acc, grade) => acc + grade.grade, 0);
    return sum / grades.length;
  };

  // Додаємо до студентів поле averageGrade з їх середньою оцінкою
  const studentsWithAverageGrade = students.map((student) => ({
    ...student,
    averageGrade: calculateAverageGrade(student.grades),
  }));

  // Сортуємо студентів за спаданням середньої оцінки
  const sortedStudents = studentsWithAverageGrade.sort(
    (a, b) => b.averageGrade - a.averageGrade
  );

  return (
    <div className=" text-white flex flex-col gap-5">
      <div className="flex gap-3">
        <svg
          fill="white"
          height="32px"
          width="32px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M454.194,8.258H148.645v495.484h305.548c31.926,0,57.806-25.881,57.806-57.806V66.065
C512,34.139,486.119,8.258,454.194,8.258z M342.07,371.613l-14.002-45.267h-61.554l-13.677,45.267h-64.811l80.443-231.226h57.967
l80.118,231.226H342.07z"
              />
            </g>
          </g>
          <g>
            <g>
              <polygon points="297.456,212.358 275.958,284.006 317.97,284.006 " />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M49.548,8.258v89.739H24.774C11.092,97.997,0,109.089,0,122.771s11.092,24.774,24.774,24.774h24.774v82.581H24.774
C11.092,230.126,0,241.218,0,254.9s11.092,24.774,24.774,24.774h24.774v82.581H24.774C11.092,362.255,0,373.347,0,387.029
s11.092,24.774,24.774,24.774h24.774v91.939h49.548V8.258H49.548z"
              />
            </g>
          </g>
        </svg>
        <h1 className="text-2xl font-bold ">Рейтинг</h1>
      </div>
      <div className="flex flex-wrap gap-4 overflow-y-auto max-h-52">
        {sortedStudents.map((student) => (
          <StudentCard key={student._id} student={student} />
        ))}
      </div>
    </div>
  );
};

export default TopStudentsList;
