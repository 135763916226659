import React, { useState, useEffect } from "react";
import {
  getAllSubjects,
  getAllStudents,
  getGroups,
  changeStudentGroup,
} from "../services/api";
import SubjectBarChart from "../Student/SubjectBarChart";
import GroupCard from "../Global/GroupCard";
import AddGrade from "./AddGrade"; // Імпортуємо компонент AddGrade
import SubjectCard from "../Global/SubjectCard";

const StudentDashboard = () => {
  const [userEmail, setUserEmail] = useState("");
  const [studentId, setStudentId] = useState("");
  const [studentGrades, setStudentGrades] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  useEffect(() => {
    const fetchEmail = async () => {
      const currentUserCookie = getCookie("currentUser");
      if (currentUserCookie) {
        const user = JSON.parse(currentUserCookie);
        if (user && user.email) {
          setUserEmail(user.email);
        }
      }
    };

    fetchEmail();
  }, []);
  
  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const subjectsData = await getAllSubjects();
        setSubjects(subjectsData);
        const groupData = await getGroups();
        setGroups(groupData);
        if (userEmail) {
          const studentsData = await getAllStudents();
          const currentStudent = studentsData.find(
            (student) => student.email === userEmail
          );
          if (currentStudent.group) {
            setSelectedGroupId(currentStudent.group);
          }
          if (currentStudent) {
            setStudentId(currentStudent._id);
            console.log(currentStudent._id);
            const processedGrades = calculateStudentSubjectStats(
              currentStudent,
              subjectsData
            );
            setStudentGrades(processedGrades);
          } else {
            console.error("No student found with the provided email.");
          }
        }
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    fetchStudentData();
  }, [userEmail]);

  const calculateStudentSubjectStats = (student, subjectsData) => {
    if (!student || !student.grades || student.grades.length === 0) return null;

    const subjectsStats = {};

    student.grades.forEach((gradeObj) => {
      const { subject, grade } = gradeObj;

      if (!subjectsStats[subject]) {
        const subjectName =
          subjectsData.find((sub) => sub._id === subject)?.name || subject;
        subjectsStats[subject] = {
          name: subjectName,
          min: Number.MAX_VALUE,
          max: Number.MIN_VALUE,
          total: 0,
          count: 0,
        };
      }

      const subjectStats = subjectsStats[subject];

      if (grade < subjectStats.min) subjectStats.min = grade;
      if (grade > subjectStats.max) subjectStats.max = grade;
      subjectStats.total += grade;
      subjectStats.count++;
    });

    // Обчислюємо середній бал для кожного предмета
    Object.keys(subjectsStats).forEach((subject) => {
      const subjectStats = subjectsStats[subject];
      subjectStats.avg = subjectStats.count
        ? (subjectStats.total / subjectStats.count).toFixed(2)
        : 0;
    });

    return subjectsStats;
  };

  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? cookieValue.pop() : null;
  };

  const handleGroupCardClick = async (groupId) => {
    setSelectedGroupId(groupId);
    try {
      const changeGroupResponse = await changeStudentGroup(studentId, groupId);
      setSelectedGroupId(groupId);
    } catch (error) {
      console.error("Error changing the group:", error);
      // Опціонально, додайте повідомлення про помилку або сповіщення тут
    }
  };

  const handleGradeAdded = () => {
    // Перезавантажити або оновити оцінки студента
    const fetchUpdatedStudentData = async () => {
      try {
        const studentsData = await getAllStudents();
        const currentStudent = studentsData.find(
          (student) => student.email === userEmail
        );
        if (currentStudent) {
          const processedGrades = calculateStudentSubjectStats(
            currentStudent,
            subjects
          );
          setStudentGrades(processedGrades);
        }
      } catch (error) {
        console.error("Error fetching updated student data:", error);
      }
    };

    fetchUpdatedStudentData();
  };

  return (
    <div className="p-6 bg-lighter text-white ">
      <div className="flex gap-5">
        <div className="bg-darker-3 w-fit max-h-fit p-6 rounded-md flex flex-col justify-center">
          <div className="flex flex-row gap-2">
            <svg
              width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM13.75 10C13.75 10.4142 14.0858 10.75 14.5 10.75H15.1893L13.1768 12.7626C13.0791 12.8602 12.9209 12.8602 12.8232 12.7626L11.2374 11.1768C10.554 10.4934 9.44598 10.4934 8.76256 11.1768L6.46967 13.4697C6.17678 13.7626 6.17678 14.2374 6.46967 14.5303C6.76256 14.823
                2 14.2374 7.23744 14.8232 6.76256 14.5303L9.82322 12.2374C9.92085 12.1398 10.0791 12.1398 10.1768 12.2374L11.7626 13.8232C12.446 14.5066 13.554 14.5066 14.2374 13.8232L16.25 11.8107V12.5C16.25 12.9142 16.5858 13.25 17 13.25C17.4142 13.25 17.75 12.9142 17.75 12.5V10C17.75 9.58579 17.4142 9.25 17 9.25H14.5C14.0858 9.25 13.75 9.58579 13.75 10Z"
                fill="white"
              />
            </svg>
            <h1 className="text-2xl font-bold mb-4">Розподіл оцінок</h1>
          </div>
          <p>
            *На розподілі оцінок зазначаються лише предмети, за які є оцінки.
          </p>
          <br />
          {studentGrades && (
            <SubjectBarChart subjectsData={Object.values(studentGrades)} />
          )}
        </div>
        <div className="flex flew-row gap-2 bg-darker-3 w-fit p-6 rounded-md justify-center">
          <div className="flex flex-col gap-3 ">
            <div className="flex gap-3">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM2 17.25C2 14.92 6.66 13.75 9 13.75C11.34 13.75 16 14.92 16 17.25V19H2V17.25ZM9 15.75C7.21 15.75 5.18 16.42 4.34 17H13.66C12.82 16.42 10.79 15.75 9 15.75ZM10.5 8.5C10.5 7.67 9.83 7 9 7C8.17 7 7.5 7.67 7.5 8.5C7.5 9.33 8.17 10 9 10C9.83 10 10.5 9.33 10.5 8.5ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM18.5 8.5C18.5 10.43 16.93 12 15 12C14.46 12 13.96 11.87 13.5 11.65C14.13 10.76 14.5 9.67 14.5 8.5C14.5 7.33 14.13 6.24 13.5 5.35C13.96 5.13 14.46 5 15 5C16.93 5 18.5 6.57 18.5 8.5Z"
                  fill="white"
                />
              </svg>
              <h1 className="text-2xl font-bold mb-4">Групи</h1>
            </div>
            <div className="overflow-y-auto max-h-96 flex flex-col gap-3">
              {groups.map((group) => (
                <GroupCard
                  key={group._id}
                  group={group}
                  isSelected={group._id === selectedGroupId}
                  onClick={() => handleGroupCardClick(group._id)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col w-full gap-2 bg-darker-3 p-6  rounded-md justify-center">
            <div className="flex flew-row gap-3">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 4.84969V16.7397C22 17.7097 21.21 18.5997 20.24 18.7197L19.93 18.7597C18.29 18.9797 15.98 19.6597 14.12 20.4397C13.47 20.7097 12.75 20.2197 12.75 19.5097V5.59969C12.75 5.22969 12.96 4.88969 13.29 4.70969C15.12 3.71969 17.89 2.83969 19.77 2.67969H19.83C21.03 2.67969 22 3.64969 22 4.84969Z"
                  fill="white"
                />
                <path
                  d="M10.7083 4.70969C8.87828 3.71969 6.10828 2.83969 4.22828 2.67969H4.15828C2.95828 2.67969 1.98828 3.64969 1.98828 4.84969V16.7397C1.98828 17.7097 2.77828 18.5997 3.74828 18.7197L4.05828 18.7597C5.69828 18.9797 8.00828 19.6597 9.86828 20.4397C10.5183 20.7097 11.2383 20.2197 11.2383 19.5097V5.59969C11.2383 5.21969 11.0383 4.88969 10.7083 4.70969ZM4.99828 7.73969H7.24828C7.65828 7.73969 7.99828 8.07969 7.99828 8.48969C7.99828 8.90969 7.65828 9.23969 7.24828 9.23969H4.99828C4.58828 9.23969 4.24828 8.90969 4.24828 8.48969C4.24828 8.07969 4.58828 7.73969 4.99828 7.73969ZM7.99828 12.2397H4.99828C4.58828 12.2397 4.24828 11.9097 4.24828 11.4897C4.24828 11.0797 4.58828 10.7397 4.99828 10.7397H7.99828C8.40828 10.7397 8.74828 11.0797 8.74828 11.4897C8.74828 11.9097 8.40828 12.2397 7.99828 12.2397Z"
                  fill="white"
                />
              </svg>
              <h1 className="text-xl font-bold">Предмети:</h1>
            </div>
            <div className="flex flex-row flex-wrap gap-3 overflow-y-auto max-h-20">
              {subjects.map((subject) => (
                <SubjectCard key={subject._id} subject={subject} />
              ))}
            </div>
          </div>
          <div className="flex flex-col max-h-fit w-full gap-2 bg-darker-3 p-6  rounded-md justify-center">
            {studentId && (
              <AddGrade
                studentId={studentId}
                onGradeAdded={handleGradeAdded}
                className="flex-grow"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
