import React, { useState, useEffect } from 'react';
import { getGroups } from '../services/api'; // импортируем функции для получения данных с сервера
import { default as StudentsTable } from './StudentsTable';
import Sidebar from './Sidebar';
import SearchBar from '../Global/SearchBar';

const TeacherDashboard = () => {
  return (
        <div className="w-full h-full">
            <StudentsTable />
        </div>
  );
};

export default TeacherDashboard;
