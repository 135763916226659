import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import StudentDashboard from "../components/Student/StudentDashboard";
import SearchBar from "../components/Global/SearchBar";
import Sidebar from "../components/Student/Sidebar";
import SearchResults from "../components/Global/SearchResults";
import UserInfo from "../components/Global/Login/UserInfo";
import SubjectList from "../components/Student/SubjectList";
import GroupList from "../components/Student/GroupList";
import GradeList from "../components/Student/GradeList"

const StudentRoutes = () => {
  useEffect(() => {
    const hasRefreshed = sessionStorage.getItem('hasRefreshed');
    if (!hasRefreshed) {
      sessionStorage.setItem('hasRefreshed', 'true');
      window.location.reload();
    }
  }, []);
  return (
    <div>
      <Sidebar />
      <div className="sm:ml-64 h-full">
        <SearchBar />
        <div className="w-full h-full">
          <Routes>
            <Route path="/" element={<StudentDashboard />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/user" element={<UserInfo/>} />
            <Route path="/subjects" element={<SubjectList/>} />
            <Route path="/groups" element={<GroupList/>} />
            <Route path="/grades" element={<GradeList/>} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default StudentRoutes;
