import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Импортируем компонент Link
import { getGroups, deleteGroup, addGroup, editGroup } from '../services/api';

const GroupList = () => {
  const [groups, setGroups] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');
  const [editingGroupId, setEditingGroupId] = useState('');
  const [editGroupName, setEditGroupName] = useState('');

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const data = await getGroups();
        setGroups(data);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, []);

  const handleEditGroup = (groupId, groupName) => {
    setEditingGroupId(groupId);
    setEditGroupName(groupName);
  };

  const handleSaveGroup = async (groupId) => {
    try {
      await editGroup(groupId, editGroupName);
      const updatedGroups = groups.map(group => {
        if (group._id === groupId) {
          return { ...group, name: editGroupName };
        }
        return group;
      });
      setGroups(updatedGroups);
      setEditingGroupId('');
    } catch (error) {
      console.error('Error saving group:', error);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await deleteGroup(groupId);
      const updatedGroups = groups.filter(group => group._id !== groupId);
      setGroups(updatedGroups);
    } catch (error) {
      console.error('Error deleting group:', error);
    }
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();
    try {
      const newGroup = await addGroup(newGroupName);
      setGroups([...groups, newGroup]);
      setNewGroupName('');
    } catch (error) {
      console.error('Error adding group:', error);
    }
  };

  return (
    <div className="p-6 bg-lighter w-1/2 text-white">
      <h1 className="text-2xl font-bold mb-4">Список груп</h1>
      <form onSubmit={handleAddGroup} className="mb-4 flex flex-row gap-3">
        <input
          type="text"
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
          placeholder="Введіть назву групи"
          className="bg-lav rounded placeholder-gray-400"
        />
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
          Додати групу
        </button>
      </form>
      <table className="w-full">
        <thead>
          <tr className="bg-darker-3">
            <th className="text-left py-2 px-4">Назва</th>
            <th className="text-left py-2 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {groups.map(group => (
            <tr key={group._id} className="border-b border-gray-700">
              <td className="py-2 px-4 bg-darker">
                {editingGroupId === group._id ? (
                  <input
                    type="text"
                    value={editGroupName}
                    onChange={(e) => setEditGroupName(e.target.value)}
                    className="border border-gray-400 p-2 bg-darker text-white"
                  />
                ) : (
                  <Link to={`/teacher/students/${group._id}`} className="text-blue-500 hover:underline">
                    {group.name}
                  </Link>
                )}
              </td>
              <td className="py-2 px-4 bg-darker">
                {editingGroupId === group._id ? (
                  <button
                    onClick={() => handleSaveGroup(group._id)}
                    className="bg-green-500 text-white py-2 px-4 rounded mr-2"
                  >
                    OK
                  </button>
                ) : (
                  <button
                    onClick={() => handleEditGroup(group._id, group.name)}
                    className="bg-orange-500 text-white py-2 px-4 rounded mr-2"
                  >
                    Edit
                  </button>
                )}
                <button
                  onClick={() => handleDeleteGroup(group._id)}
                  className="bg-red-500 text-white py-2 px-4 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GroupList;
