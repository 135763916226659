import React from "react";

const SubjectCard = ({ subject }) => {
  return (
    <div className="w-fit p-4 bg-darker rounded-md text-white flex justify-center items-center hover:bg-orange-500">
      <div className="flex flex-row items-center gap-2">
        <div>
          <h1 className="text-center font-semibold text-white">{subject.name}</h1>
        </div>
      </div>
    </div>
  );
};

export default SubjectCard;
