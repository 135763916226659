import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllStudents, getSubjects } from '../services/api';
import { Cookies } from 'react-cookie';
import UserDropdown from '../Global/Login/UserDropdown'; // Імпортуємо новий компонент

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('students');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies(); // Ініціалізуємо Cookies

  const handleSearch = async (event) => {
    event.preventDefault();
    const currentUser = cookies.get("currentUser"); 
    var role = '';
    if (currentUser) {
      if(currentUser.role === "teacher") { role = '/teacher'; }
      if(currentUser.role === "student") { role = '/student'; }
    }
    try {
      let data;
      if (filter === 'students') {
        data = await getAllStudents();
        const results = data.filter(student => student.name.toLowerCase().includes(searchTerm.toLowerCase()));
        navigate(role+'/search', { state: { results, filter } });
      } else if (filter === 'subjects') {
        data = await getSubjects();
        const results = data.filter(subject => subject.name.toLowerCase().includes(searchTerm.toLowerCase()));
        navigate(role+'/search', { state: { results, filter } });
      }
    } catch (error) {
      console.error('Помилка отримання даних:', error);
    }
  };

  const handleFilterChange = (filter) => {
    setFilter(filter);
    setDropdownOpen(false);
  };

  return (
    <div>
      <div className="px-4 py-4 bg-main"> {/* Змінено тут */}
        <div className="max-w-full mx-auto black-border flex justify-between items-center">
          <form onSubmit={handleSearch} className="flex-grow mr-6">
            <div className="flex w-full relative">
              <button
                id="dropdown-button"
                className="flex-shrink-0 font-semibold z-10 inline-flex items-center py-2.5 px-5 text-sm font-medium text-center text-white bg-darker-3 rounded-s-2xl"
                type="button"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {filter === 'students' ? 'Студенти' : 'Предмети'}
                <svg
          className="w-2.5 h-2.5 ml-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg> 
              </button>
              {dropdownOpen && (
                <div className="absolute z-10 bg-darker rounded-md shadow w-25 mt-12">
                  <ul className="py-2 text-sm text-gray-200">
                    <li>
                      <button
                        type="button"
                        onClick={() => handleFilterChange('students')}
                        className="inline-flex font-semibold w-full px-8 py-2 hover:text-white"
                      >
                        Студенти
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => handleFilterChange('subjects')}
                        className="inline-flex font-semibold w-full px-8 py-2 hover:text-white"
                      >
                        Предмети
                      </button>
                    </li>
                  </ul>
                </div>
              )}
              <div className="relative w-full">
                <input
                  type="search"
                  id="search-dropdown"
                  className="block p-3 w-full font-semibold z-20 text-sm text-white bg-lav rounded-e-2xl placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Шукати студентів або предмети..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className="absolute top-0 right-0 p-3 text-sm font-medium h-full text-white bg-orange-500 rounded-e-2xl focus:ring-4 focus:outline-none dark:bg-blue-600 dark:hover:bg-lav dark:focus:ring-blue-800"
                >
                  <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
                  <span className="sr-only">Пошук</span>
                </button>
              </div>
            </div>
          </form>
          <UserDropdown />
        </div>
        
      </div>
    </div>
  );
};

export default SearchBar;
