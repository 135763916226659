import React from 'react';
import { useLocation } from 'react-router-dom';

const SearchResults = () => {
  const location = useLocation();
  const { results, filter } = location.state || { results: [], filter: '' };

  return (
    <div className="p-6 bg-lighter text-white">
      <h1 className="text-2xl font-bold mb-4">Результати пошуку</h1>
      {results.length > 0 ? (
        <ul className="list-disc ml-8">
          {results.map((result) => (
            <li key={result._id}>{result.name}</li>
          ))}
        </ul>
      ) : (
        <p>Немає результатів</p>
      )}
    </div>
  );
};

export default SearchResults;
