import React, { useState, useEffect } from 'react';
import { getGroups, changeStudentGroup, getAllStudents } from '../services/api';

const GroupList = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [studentId, setStudentId] = useState("");

  useEffect(() => {
    const fetchEmail = async () => {
      const currentUserCookie = getCookie("currentUser");
      if (currentUserCookie) {
        const user = JSON.parse(currentUserCookie);
        if (user && user.email) {
          setUserEmail(user.email);
        }
      }
    };

    fetchEmail();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const data = await getGroups();
        setGroups(data);
      } catch (error) {
        console.error('Помилка при отриманні груп:', error);
      }
    };

    fetchGroups();
  }, []);
  
  useEffect(() => {
    const fetchStudentGroup = async () => {
      try {
        if (userEmail) {
            const studentsData = await getAllStudents();
            const currentStudent = studentsData.find(
              (student) => student.email === userEmail
            );
            if (currentStudent.group) {
              setSelectedGroupId(currentStudent.group);
            }
            setStudentId(currentStudent._id);
        }
      } catch (error) {
        console.error('Помилка при отриманні групи студента:', error);
      }
    };

    fetchStudentGroup();
  }, [userEmail]);

  const handleGroupChange = async (groupId) => {
    setSelectedGroupId(groupId);
    try {
      const changeGroupResponse = await changeStudentGroup(studentId, groupId);
      setSelectedGroupId(groupId);
    } catch (error) {
      console.error("Помилка при зміні групи:", error);
      // Опціонально, додайте повідомлення про помилку або сповіщення тут
    }
  };

  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? cookieValue.pop() : null;
  };

  return (
    <div className="p-6 bg-lighter w-1/2 text-white">
      <h1 className="text-2xl font-bold mb-4">Список Груп</h1>
      <table className="w-full">
        <thead>
          <tr className="bg-darker-3">
            <th className="text-left py-2 px-4">Назва</th>
            <th className="text-left py-2 px-4">Вибрати</th>
          </tr>
        </thead>
        <tbody>
          {groups.map(group => (
            <tr key={group._id} className="border-b border-gray-700">
              <td className="py-2 px-4 bg-darker">{group.name}</td>
              <td className="py-2 px-4 bg-darker">
                <input
                  type="radio"
                  value={group._id}
                  checked={selectedGroupId === group._id}
                  onChange={() => handleGroupChange(group._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GroupList;
