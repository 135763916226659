import React, { useState, useEffect } from "react";
import { getAllStudents, getAllSubjects } from "../services/api"; // Імпорт функцій API для отримання інформації про студентів і предметів
import AddGrade from "./AddGrade";
import DeleteGrade from "./DeleteGrade";

const GradeList = () => {
  const [subjectId, setSubjectId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentId, setStudentId] = useState(""); // Зберігаємо ID студента
  const [students, setStudents] = useState([]);
  const [subjects, setSubjects] = useState([]); // Зберігаємо список предметів

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const studentData = await getAllStudents(); // Отримуємо список всіх студентів з сервера
        setStudents(studentData);

        const subjectData = await getAllSubjects(); // Отримуємо список всіх предметів з сервера
        setSubjects(subjectData);
      } catch (error) {
        console.error("Помилка при отриманні даних:", error);
      }
    };

    fetchStudents();
  }, []);

  useEffect(() => {
    const fetchNameAndId = async () => {
      const currentUserCookie = getCookie("currentUser");
      if (currentUserCookie) {
        const user = JSON.parse(currentUserCookie);
        if (user && user.nickname) {
          setStudentEmail(user.email);
          
          // Знаходимо студента за email і зберігаємо його ID
          const matchedStudent = students.find(student => student.email === user.email);
          if (matchedStudent) {
            setStudentId(matchedStudent._id);
            setStudentName(matchedStudent.name);
          }
        }
      }
    };

    fetchNameAndId();
  }, [students]); // Залежність від students

  const handleSelectChange = (event) => {
    const selectedSubjectId = event.target.value;
    setSubjectId(selectedSubjectId);
  };

  const getGradesForSubject = (student) => {
    if (!subjectId) return []; // Повертаємо порожній масив, якщо предмет не вибрано
    return student.grades.filter((grade) => grade.subject === subjectId);
  };

  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? cookieValue.pop() : null;
  };

  const getSubjectById = (id) => {
    return subjects.find((subject) => subject._id === id);
  };

  const filteredStudents = () => {
    const subject = getSubjectById(subjectId);
    if (subject && !subject.publicRating) {
      return students.filter(student => student.name === studentName);
    }
    return students;
  };

  const handleGradeAdded = async () => {
    try {
      const updatedStudents = await getAllStudents(); // Повторно отримуємо список всіх студентів
      setStudents(updatedStudents); // Оновлюємо стан студентів
    } catch (error) {
      console.error("Помилка при отриманні оновлених даних про студентів:", error);
    }
  };

  return (
    <div className="p-6 bg-lighter text-white">
      <h1 className="text-2xl font-bold mb-4">Таблиця оцінок</h1>
      <div className="mb-4">
        <label htmlFor="subject-select" className="mr-2 text-gray-300">
          Виберіть предмет зі списку:
        </label>
        <select
          id="subject-select"
          value={subjectId}
          onChange={handleSelectChange}
          className="border-none h-1/4 rounded bg-darker text-gray-300"
        >
          <option value="">Предмет...</option>
          {/* Проходимося по списку предметів для випадаючого списку */}
          {subjects.map((subject) => (
            <option key={subject._id} value={subject._id}>
              {subject.name}
            </option>
          ))}
        </select>
      </div>
      <table className="w-1/2">
        <thead>
          <tr className="bg-darker text-white">
            <th className="py-2 px-4 bg-darker-3">Ім'я</th>
            <th className="py-2 px-4 bg-darker-3">Оцінка</th>
          </tr>
        </thead>
        <tbody className="text-gray-300">
          {/* Проходимося по студентах для таблиці */}
          {filteredStudents().map((student) => (
            <tr key={student._id} className="border-b border-gray-700">
              <td className="py-2 px-4 bg-darker">{student.name}</td>
              <td className="py-2 px-4 bg-darker">
                {/* Виводимо оцінки для вибраного предмету */}
                {(() => {
                  const grades = getGradesForSubject(student);
                  if (grades.length === 0) {
                    return <div>Оцінок поки немає.</div>;
                  } else {
                    return (
                      <div>{grades.map((grade) => grade.grade).join(", ")}</div>
                    );
                  }
                })()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <div className="flex flex-row max-h-fit w-full gap-6 p-6  rounded-md justify-center">
        {studentId && (
          <AddGrade
            studentId={studentId}
            onGradeAdded={handleGradeAdded}
            className="flex-grow"
          />
        )}
        {studentId && (
          <DeleteGrade
            studentId={studentId}
            onGradeDeleted={handleGradeAdded}
            className="flex-grow"
          />
        )}
      </div>
    </div>
  );
};

export default GradeList;
