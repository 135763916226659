import React, { useState, useEffect } from 'react';
import { getAllSubjects, addGradeForStudent } from '../services/api';

const AddGrade = ({ studentId, onGradeAdded }) => {
  const [grade, setGrade] = useState(0);
  const [subjectId, setSubjectId] = useState('');
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const subjectsData = await getAllSubjects();
        // Фільтруємо предмети, лишаємо тільки ті, для яких acceptsGrades === true
        const filteredSubjects = subjectsData.filter(subject => subject.acceptsGrades);
        setSubjects(filteredSubjects);
        if (filteredSubjects.length > 0) {
          setSubjectId(filteredSubjects[0]._id); // Встановлюємо вибраний предмет за замовчуванням
        }
      } catch (error) {
        console.error('Помилка отримання предметів:', error);
      }
    };

    fetchSubjects();
  }, []);

  const handleGradeChange = (e) => {
    setGrade(Number(e.target.value));
  };

  const handleSubjectChange = (e) => {
    setSubjectId(e.target.value);
  };

  const handleAddGrade = async () => {
    if (subjectId && grade >= 0 && grade <= 100) {
      try {
        await addGradeForStudent(studentId, subjectId, grade);
        onGradeAdded(); // Зворотний виклик для сповіщення батьківського компонента про додання оцінки
      } catch (error) {
        console.error('Помилка додавання оцінки:', error);
      }
    } else {
      console.error('Недійсний предмет або оцінка');
    }
  };

  return (
    <div className="bg-darker p-4 w-full text-white rounded-lg w-fit">
      <h2 className="text-xl mb-4">Додати Оцінку</h2>
      <div className="mb-4">
        <label className="block mb-2">Оцінка:</label>
        <input type="number" value={grade} onChange={handleGradeChange} min="0" max="100" className="py-2 bg-darker text-white rounded-lg w-full" />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Предмет:</label>
        <select value={subjectId} onChange={handleSubjectChange} className="py-2 bg-darker text-white rounded-lg w-full">
          {subjects.map((subject) => (
            <option key={subject._id} value={subject._id}>
              {subject.name}
            </option>
          ))}
        </select>
      </div>
      <button onClick={handleAddGrade} className="bg-blue-500 hover:bg-blue-600 py-2 rounded-lg w-full">Додати Оцінку</button>
    </div>
  );
};

export default AddGrade;
