import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../../static/img/logo.svg"; // Path to your image
import dragon from "../../static/img/dancing-toothless-tothless.gif";
const Sidebar = () => {
  const [showContainer, setShowContainer] = useState(true);

  const handleRemoveContainer = () => {
    setShowContainer(false);
  };

  return (
    <div>
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-400 rounded-md sm:hidden hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </button>

      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 items-center z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-darker-2">
          <a
            href="https://markspace.info/teacher"
            className="flex items-center ps-2.5 mb-5"
          >
            <img src={logo} className="h-6 me-3 sm:h-12" alt="Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
              MarkSpace
            </span>
          </a>
          <ul className="space-y-2 font-medium">
            <li>
            <NavLink
              to="/teacher"
              className="flex items-center p-2 text-gray-400 rounded-md hover:bg-gray-700 group"
            >
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 -0.5 21 21"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Dribbble-Light-Preview"
                      transform="translate(-99.000000, -200.000000)"
                      fill="white"
                    >
                      <g
                        id="icons"
                        transform="translate(56.000000, 160.000000)"
                      >
                        <path
                          d="M60.85,51 L57.7,51 C55.96015,51 54.55,52.343 54.55,54 L54.55,57 C54.55,58.657 55.96015,60 57.7,60 L60.85,60 C62.58985,60 64,58.657 64,57 L64,54 C64,52.343 62.58985,51 60.85,51 M49.3,51 L46.15,51 C44.41015,51 43,52.343 43,54 L43,57 C43,58.657 44.41015,60 46.15,60 L49.3,60 C51.03985,60 52.45,58.657 52.45,57 L52.45,54 C52.45,52.343 51.03985,51 49.3,51 M60.85,40 L57.7,40 C55.96015,40 54.55,41.343 54.55,43 L54.55,46 C54.55,47.657 55.96015,49 57.7,49 L60.85,49 C62.58985,49 64,47.657 64,46 L64,43 C64,41.343 62.58985,40 60.85,40 M52.45,43 L52.45,46 C52.45,47.657 51.03985,49 49.3,49 L46.15,49 C44.41015,49 43,47.657 43,46 L43,43 C43,41.343 44.41015,40 46.15,40 L49.3,40 C51.03985,40 52.45,41.343 52.45,43"
                          id="menu_navigation_grid-[#1529]"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>

                <span className="ms-3">Огляд</span>
              </NavLink>
            </li>
            <li>
            <NavLink
              to="/teacher/subjects"
              className="flex items-center p-2 text-gray-400 rounded-md hover:bg-gray-700 group"
            >
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 4.84969V16.7397C22 17.7097 21.21 18.5997 20.24 18.7197L19.93 18.7597C18.29 18.9797 15.98 19.6597 14.12 20.4397C13.47 20.7097 12.75 20.2197 12.75 19.5097V5.59969C12.75 5.22969 12.96 4.88969 13.29 4.70969C15.12 3.71969 17.89 2.83969 19.77 2.67969H19.83C21.03 2.67969 22 3.64969 22 4.84969Z"
                    fill="white"
                  />
                  <path
                    d="M10.7083 4.70969C8.87828 3.71969 6.10828 2.83969 4.22828 2.67969H4.15828C2.95828 2.67969 1.98828 3.64969 1.98828 4.84969V16.7397C1.98828 17.7097 2.77828 18.5997 3.74828 18.7197L4.05828 18.7597C5.69828 18.9797 8.00828 19.6597 9.86828 20.4397C10.5183 20.7097 11.2383 20.2197 11.2383 19.5097V5.59969C11.2383 5.21969 11.0383 4.88969 10.7083 4.70969ZM4.99828 7.73969H7.24828C7.65828 7.73969 7.99828 8.07969 7.99828 8.48969C7.99828 8.90969 7.65828 9.23969 7.24828 9.23969H4.99828C4.58828 9.23969 4.24828 8.90969 4.24828 8.48969C4.24828 8.07969 4.58828 7.73969 4.99828 7.73969ZM7.99828 12.2397H4.99828C4.58828 12.2397 4.24828 11.9097 4.24828 11.4897C4.24828 11.0797 4.58828 10.7397 4.99828 10.7397H7.99828C8.40828 10.7397 8.74828 11.0797 8.74828 11.4897C8.74828 11.9097 8.40828 12.2397 7.99828 12.2397Z"
                    fill="white"
                  />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Предмети</span>
              </NavLink>
            </li>
            <li>
            <NavLink
              to="/teacher/groups"
              className="flex items-center p-2 text-gray-400 rounded-md hover:bg-gray-700 group"
            >
                <svg
                  fill="white"
                  height="25px"
                  width="25px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 310.746 310.746"
                >
                  <g id="XMLID_227_">
                    <path
                      id="XMLID_228_"
                      d="M32.622,75.373c0,23.666,18.367,43.109,41.594,44.857c-7.382-13.302-11.594-28.596-11.594-44.857
s4.212-31.556,11.594-44.857C50.989,32.264,32.622,51.707,32.622,75.373z"
                    />
                    <path
                      id="XMLID_229_"
                      d="M0,227.996c0,8.284,6.716,15,15,15h1.025c11.602-40.229,40.192-73.322,77.464-90.984
c-5.17-1.077-10.482-1.639-15.867-1.639C34.821,150.373,0,185.194,0,227.996z"
                    />
                    <path
                      id="XMLID_230_"
                      d="M278.124,75.373c0-23.666-18.367-43.109-41.594-44.857c7.382,13.302,11.594,28.596,11.594,44.857
s-4.212,31.556-11.594,44.857C259.757,118.482,278.124,99.039,278.124,75.373z"
                    />
                    <path
                      id="XMLID_231_"
                      d="M295.746,242.996c8.284,0,15-6.716,15-15c0-42.802-34.821-77.623-77.622-77.623
c-5.385,0-10.697,0.561-15.867,1.639c37.271,17.662,65.862,50.756,77.464,90.984H295.746z"
                    />
                    <path
                      id="XMLID_232_"
                      d="M155.373,138.123c21.093,0,39.774-10.473,51.157-26.479c7.289-10.25,11.594-22.764,11.594-36.271
s-4.305-26.021-11.594-36.271c-11.383-16.006-30.064-26.479-51.157-26.479c-34.601,0-62.75,28.149-62.75,62.75
S120.772,138.123,155.373,138.123z"
                    />
                    <path
                      id="XMLID_233_"
                      d="M270.373,283.123c0-14.107-2.56-27.627-7.229-40.127c-13.457-36.025-44.485-63.549-82.606-72.092
c-8.102-1.816-16.522-2.781-25.165-2.781c-63.411,0-115,51.589-115,115c0,8.284,6.716,15,15,15h200
C263.657,298.123,270.373,291.407,270.373,283.123z"
                    />
                  </g>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Групи</span>
              </NavLink>
            </li>
            <li>
            <NavLink
              to="/teacher/grades"
              className="flex items-center p-2 text-gray-400 rounded-md hover:bg-gray-700 group"
            >
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 12V8C2 5.17157 2 3.75736 2.87868 2.87868C3.75736 2 5.17157 2 8 2H16C18.8284 2 20.2426 2 21.1213 2.87868C22 3.75736 22 5.17157 22 8V12C22 14.8284 22 16.2426 21.1213 17.1213C20.645 17.5977 20.0113 17.8158 19.0802 17.9156C19.0062 17.8157 18.9362 17.737 18.8862 17.6821C18.7701 17.5544 18.6228 17.4092 18.48 17.2684L16.987 15.7962L16.4357 15.2377C16.0733 13.1151 14.2255 11.5 12.0001 11.5C9.77461 11.5 7.9268 13.1151 7.56446 15.2377L7.01311 15.7962L5.5202 17.2683C5.37739 17.4091 5.23008 17.5543 5.11392 17.6821C5.06395 17.737 4.9939 17.8157 4.9199 17.9156C3.98877 17.8158 3.35503 17.5977 2.87868 17.1213C2 16.2426 2 14.8284 2 12ZM9 5.25C8.58579 5.25 8.25 5.58579 8.25 6C8.25 6.41421 8.58579 6.75 9 6.75H15C15.4142 6.75 15.75 6.41421 15.75 6C15.75 5.58579 15.4142 5.25 15 5.25H9ZM6.25 9.5C6.25 9.08579 6.58579 8.75 7 8.75H17C17.4142 8.75 17.75 9.08579 17.75 9.5C17.75 9.91421 17.4142 10.25 17 10.25H7C6.58579 10.25 6.25 9.91421 6.25 9.5Z"
                    fill="white"
                  />
                  <path
                    d="M15 16C15 17.6569 13.6569 19 12 19C10.3431 19 9 17.6569 9 16C9 14.3431 10.3431 13 12 13C13.6569 13 15 14.3431 15 16Z"
                    fill="white"
                  />
                  <path
                    d="M9.00111 15.9174C9.00037 15.9449 9 15.9724 9 16C9 17.6569 10.3431 19 12 19C13.6569 19 15 17.6569 15 16C15 15.9724 14.9996 15.9449 14.9989 15.9174C14.9551 14.2987 13.6292 13 12 13C10.4467 13 9.16912 14.1805 9.01549 15.6933C9.00798 15.7672 9.00315 15.842 9.00111 15.9174Z"
                    fill="white"
                  />
                  <path
                    d="M7.6757 17.2494L6.59523 18.3148C6.27116 18.6344 6.10913 18.7942 6.05306 18.9295C5.92529 19.2378 6.03463 19.5799 6.31283 19.7421C6.43492 19.8132 6.65512 19.8354 7.09553 19.8798C7.3442 19.9048 7.46853 19.9173 7.57266 19.9554C7.80579 20.0405 7.98715 20.2193 8.07345 20.4492C8.112 20.5519 8.1247 20.6745 8.1501 20.9197C8.19509 21.354 8.21758 21.5711 8.28977 21.6915C8.45425 21.9659 8.80111 22.0737 9.1138 21.9477C9.25102 21.8924 9.41306 21.7326 9.73713 21.413L10.8175 20.343C9.30505 19.9322 8.10917 18.7524 7.6757 17.2494Z"
                    fill="white"
                  />
                  <path
                    d="M13.1825 20.343L14.2629 21.413C14.5869 21.7326 14.749 21.8924 14.8862 21.9477C15.1989 22.0737 15.5457 21.9659 15.7102 21.6915C15.7824 21.5711 15.8049 21.354 15.8499 20.9197C15.8753 20.6745 15.888 20.5519 15.9265 20.4492C16.0129 20.2193 16.1942 20.0405 16.4273 19.9554C16.5315 19.9173 16.6558 19.9048 16.9045 19.8798C17.3449 19.8354 17.5651 19.8132 17.6872 19.7421C17.9654 19.5799 18.0747 19.2378 17.9469 18.9295C17.8909 18.7942 17.7288 18.6344 17.4048 18.3148L16.3243 17.2494C15.8908 18.7524 14.6949 19.9322 13.1825 20.343Z"
                    fill="white"
                  />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Оцінки</span>
              </NavLink>
            </li>
            
          </ul>
          {showContainer && (
        <div id="dropdown-cta" className="m-4 mt-8 rounded-md bg-darker-3 relative">
          <button
            onClick={handleRemoveContainer}
            className="absolute top-0 right-0 mt-2 mr-2 bg-white-800 text-white-500 rounded-full p-1 hover:bg-white-900"
            aria-label="Close"
          >
            &times;
          </button>
          <div className="flex flex-col justify-center gap-2 p-6">
            <p className="mb-3 text-sm text-white-800">
              Цей сайт - завдання студентів з технічної практики.
              Сподіваємось, нам поставлять залік...
            </p>
            <a
              className="text-sm text-white-800 underline-none font-medium hover:text-white-900 dark:text-white-400 dark:hover:text-white-300"
              href="https://gl.vlabs.knu.ua/frecs/ce/techpractice/2023-2024/paranoramaldistribution/markspace/-/tree/main?ref_type=heads"
            >
              Наш репозиторій!
            </a>
          </div>
          <img src={dragon} className="rounded-md" alt="Dragon" />
        </div>
      )}
      <span className="absolute bottom-0 m-2 text-xs text-white-500 sm:text-center dark:text-gray-400">
        © 2024 <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" className="hover:underline">Команда 3™</a>. All Rights Reserved.
      </span>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
