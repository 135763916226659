import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const SubjectBarChart = ({ studentSubjectStats, students }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (Object.keys(studentSubjectStats).length === 0) return;

    const studentNames = Object.keys(studentSubjectStats).map(studentId =>
      students.find(student => student._id === studentId).name
    );
    const statsData = Object.values(studentSubjectStats);
    const normalizationLineData = statsData.map(stats => Math.max(stats.avg, stats.min, stats.max));
    const options = {
        series: [
          {
            name: 'Середній бал',
            type: 'column',
            data: statsData.map(stats => stats.avg),
            color: '#4ADE80', // Зеленый цвет для колонок среднего значения
          },
          {
            name: 'Мін. бал',
            type: 'column',
            data: statsData.map(stats => stats.min),
            color: '#3B82F6', // Синий цвет для колонок минимального значения
          },
          {
            name: 'Макс. бал',
            type: 'column',
            data: statsData.map(stats => stats.max),
            color: '#fbbf24', // Желтый цвет для колонок максимального значения
          },
          {
            name: 'Нормалізація',
            type: 'line',
            data: normalizationLineData,
          },
        ],
        chart: {
          height: "300px",
          width: "560px",
          type: "line",
          background: '#302F37', // Dark background color
          foreColor: '#D1D5DB', // Light text color
          toolbar: {
            show: false,
          },
          fontFamily: 'Jura',
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Загальна статистика по предмету',
          align: 'center',
          style: {
            fontSize: '20px',
            fontWeight: '600',
          },
        },
        stroke: {
          width: [0, 0, 0, 4],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadiusApplication: 'end',
            borderRadius: 8,
          },
        },
        xaxis: {
          categories: studentNames,
          labels: {
            style: {
              colors: '#D1D5DB', // Light text color for x-axis labels
              fontSize: '16px',
              fontWeight: '600',
            },
            formatter: function(value) {
              if (value) {
                  return (value.length > 5) ? value.substring(0, 10) + '..' : value;
              } else {
                  return '';
              }
          }
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: function (val) {
              return Math.round(val); // Округляем значения оси y
            },
            style: {
              colors: '#D1D5DB', // Light text color for y-axis labels
              fontSize: '16px',
              fontWeight: '600',
            },
          },
        },
        grid: {
          show: true,
          borderColor: 'rgba(244, 244, 244, 0.18)',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            left: 2,
            right: 2,
            top: -14,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          theme: 'dark', // Dark theme for tooltip
          style: {
            fontSize: '16px',
            fontWeight: '600',
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40,
          labels: {
            colors: '#D1D5DB', // Light text color for legend labels
            fontSize: '16px',
            fontWeight: '600',
          },
        },
        markers: {
          size: 4, // Marker size
          colors: ['#F87171'], // Marker color for the Нормалізація
          strokeWidth: 0, // No stroke for markers
        },
      };
    if (chartRef.current) {
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();
      return () => chart.destroy();
    }
  }, [studentSubjectStats, students]);

  return (
    <div className="bg-darker rounded-md p-4 md:p-6 flex justify-center">
      <div ref={chartRef} />
    </div>
  );
};

export default SubjectBarChart;
