import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TeacherDashboard from "../components/Teacher/TeacherDashboard";
import GroupList from "../components/Teacher/GroupList";
import GradesPage from "../components/Teacher/GradesPage";
import Sidebar from "../components/Teacher/Sidebar";
import SubjectList from "../components/Teacher/SubjectList";
import SearchBar from "../components/Global/SearchBar";
import SearchResults from "../components/Global/SearchResults";
import UserInfo from "../components/Global/Login/UserInfo";
import StudentList from "../components/Teacher/StudentList";

const TeacherRoutes = () => {
  useEffect(() => {
    const hasRefreshed = sessionStorage.getItem('hasRefreshed');
    if (!hasRefreshed) {
      sessionStorage.setItem('hasRefreshed', 'true');
      window.location.reload();
    }
  }, []);
    return (
      <div>
        <Sidebar />
        <div className="sm:ml-64 h-full">
          <SearchBar />
          <div className="w-full h-full">
            <Routes>
              <Route path="/" element={<TeacherDashboard />} />
              <Route path="/groups" element={<GroupList />} />
              <Route path="/subjects" element={<SubjectList />} />
              <Route path="/grades" element={<GradesPage />} />
              <Route path="/search" element={<SearchResults />} />
              <Route exact path="/students/:id" element={<StudentList />} />
              <Route path="/user" element={<UserInfo/>} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </div>
      </div>
    );
  };
  
  export default TeacherRoutes;
  
