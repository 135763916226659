import React, { useState, useEffect } from 'react';
import { getSubjects } from '../services/api'; // Import API functions

const SubjectList = () => {
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const data = await getSubjects(); // Get the list of subjects from the server
        setSubjects(data);
      } catch (error) {
        console.error('Помилка отримання предметів:', error);
      }
    };

    fetchSubjects();
  }, []);

  return (
    <div className="p-6 bg-lighter text-white">
      <h1 className="text-2xl font-bold mb-4">Список Предметів</h1>
      <table className="w-fit">
        <thead>
          <tr className="bg-darker-3">
            <th className="text-left py-2 px-4">Назва</th>
            <th className="text-left py-2 px-4">Приймає Оцінки</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map(subject => (
            <tr key={subject._id} className="border-b border-gray-700">
              <td className="py-2 px-4 bg-darker">
                    {subject.name}
              </td>
              <td className="py-2 px-4 bg-darker">
                  { subject.acceptsGrades ? "Так" : "Ні" }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubjectList;
