import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import "tailwindcss/tailwind.css";
import "./index.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import TeacherRoutes from "./routes/TeacherRoutes";
import StudentRoutes from "./routes/StudentRoutes";
import LoginPage from "./components/Global/Login/LoginPage";
import SignIn from "./components/Global/Login/SignIn";
import { Cookies } from 'react-cookie'; // Імпорт Cookies
import UserInfo from "./components/Global/Login/UserInfo"; // Контекст для зберігання інформації про користувача (роль, статус аутентифікації)

const UserContext = React.createContext({ role: null, isAuthenticated: false });

const cookies = new Cookies(); // Ініціалізуємо Cookies

const App = () => {
  const [role, setRole] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const currentUser = cookies.get("currentUser"); // Отримуємо дані користувача з cookies
    if (currentUser) {
      const storedRole = currentUser.role;
      setRole(storedRole);
      setIsAuthenticated(true); // Оновлюємо стан аутентифікації негайно
    }
  }, []);

  return (
    <UserContext.Provider value={{ role, isAuthenticated }}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signin" element={<SignIn />} />
          {isAuthenticated && (
            <>
              {role === "teacher" && <Route path="/teacher/*" element={<TeacherRoutes />} />}
              {role === "student" && <Route path="/student/*" element={<StudentRoutes />} />}
            </>
          )}
          {!isAuthenticated && <Route path="*" element={<Navigate to="/login" replace />} />}
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
