import React, { useState, useEffect } from 'react';
import { getAllSubjects, getAllStudents, deleteGradeForStudent } from '../services/api';

const DeleteGrade = ({ studentId, onGradeDeleted }) => {
  const [subjectId, setSubjectId] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [filteredGrades, setFilteredGrades] = useState([]);
  const [gradeId, setGradeId] = useState('');

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const subjectsData = await getAllSubjects();
        const filteredSubjects = subjectsData;
        setSubjects(filteredSubjects);
        if (filteredSubjects.length > 0) {
          setSubjectId(filteredSubjects[0]._id); // Встановити предмет за замовчуванням
        }
      } catch (error) {
        console.error('Помилка отримання предметів:', error);
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const fetchStudentGrades = async () => {
      try {
        const studentsData = await getAllStudents();
        const student = studentsData.find(student => student._id === studentId);
        if (student && student.grades) {
          setGrades(student.grades);
          if (subjectId) {
            const subjectGrades = student.grades.filter(grade => grade.subject === subjectId);
            setFilteredGrades(subjectGrades);
            if (subjectGrades.length > 0) {
              setGradeId(subjectGrades[0]._id); // Встановити оцінку за замовчуванням
            }
          }
        }
      } catch (error) {
        console.error('Помилка отримання оцінок студента:', error);
      }
    };

    fetchStudentGrades();
  }, [studentId, subjectId]);

  const handleSubjectChange = (e) => {
    const selectedSubjectId = e.target.value;
    setSubjectId(selectedSubjectId);
    const subjectGrades = grades.filter(grade => grade.subject === selectedSubjectId);
    setFilteredGrades(subjectGrades);
    if (subjectGrades.length > 0) {
      setGradeId(subjectGrades[0]._id);
    } else {
      setGradeId('');
    }
  };

  const handleGradeChange = (e) => {
    setGradeId(e.target.value);
  };

  const handleDeleteGrade = async () => {
    if (subjectId && gradeId) {
      try {
        await deleteGradeForStudent(studentId, gradeId);
        onGradeDeleted(); // Зворотний виклик для сповіщення батьківського компонента про видалення оцінки
        // Оновлення списку оцінок
        const updatedGrades = grades.filter(grade => grade._id !== gradeId);
        setGrades(updatedGrades);
        const subjectGrades = updatedGrades.filter(grade => grade.subject === subjectId);
        setFilteredGrades(subjectGrades);
        if (subjectGrades.length > 0) {
          setGradeId(subjectGrades[0]._id);
        } else {
          setGradeId('');
        }
      } catch (error) {
        console.error('Помилка видалення оцінки:', error);
      }
    } else {
      console.error('Недійсний предмет або оцінка');
    }
  };

  return (
    <div className="bg-darker p-4 w-full text-white rounded-lg w-fit">
      <h2 className="text-xl mb-4">Видалити Оцінку</h2>
      <div className="mb-4">
        <label className="block mb-2">Предмет:</label>
        <select value={subjectId} onChange={handleSubjectChange} className="py-2 bg-darker text-white rounded-lg w-full">
          {subjects.map((subject) => (
            <option key={subject._id} value={subject._id}>
              {subject.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Оцінка:</label>
        <select value={gradeId} onChange={handleGradeChange} className="py-2 bg-darker text-white rounded-lg w-full">
          {filteredGrades.map((grade) => (
            <option key={grade._id} value={grade._id}>
              {grade.grade} (ID: {grade._id})
            </option>
          ))}
        </select>
      </div>
      <button onClick={handleDeleteGrade} className="bg-red-500 hover:bg-red-600 py-2 rounded-lg w-full">Видалити Оцінку</button>
    </div>
  );
};

export default DeleteGrade;
