import React, { useState, useEffect, useContext } from "react";
import { NavLink } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";
import {
  loginWithEmail,
  signInWithGoogle as signInWithGoogleAPI,
  signInWithTwitter as signInWithTwitterAPI,
  signInWithGitHub as signInWithGitHubAPI,
} from "../../services/api";

const firebaseConfig = {
  apiKey: "AIzaSyA3EK15bwq3-G37jHmnbwD89PM8aLKPI0Y",
  authDomain: "school-dashboard-d4f01.firebaseapp.com",
  projectId: "school-dashboard-d4f01",
  storageBucket: "school-dashboard-d4f01.appspot.com",
  messagingSenderId: "791524007223",
  appId: "1:791524007223:web:1cd20e114f8540d5e88eb2",
};

const app = firebase.initializeApp(firebaseConfig);

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in
    const currentUserCookie = getCookie("currentUser");
    if (currentUserCookie) {
      const user = JSON.parse(currentUserCookie);
      setUser(user); // Set user in context
      navigateUser(user);
    }
  }, []);

  const navigateUser = (user) => {
    if (user.role === "teacher") {
      navigate("/teacher");
    } else if (user.role === "student") {
      navigate("/student");
    } else {
      setError("Unknown user role");
    }
  };

  const signInWithEmail = async () => {
    try {
      const { token, user } = await loginWithEmail(email, password);

      if (!user) {
        throw new Error("User ID not found");
      }

      setUser(user); // Set user in context
      setCookie("currentUser", JSON.stringify(user));
      
      navigateUser(user);
      
    } catch (error) {
      setError(error.message);
      console.error("Ошибка аутентификации через email:", error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);
      const idToken = await result.user.getIdToken();

      const apiResult = await signInWithGoogleAPI(idToken);
      const { token, user } = apiResult;

      if (!user) {
        throw new Error("User ID not found");
      }

      setUser(user); // Set user in context
      setCookie("currentUser", JSON.stringify(user));

      navigateUser(user);
    } catch (error) {
      setError(error.message);
      console.error("Ошибка аутентификации через Google:", error);
    }
  };

  const signInWithTwitter = async () => {
    try {
      const provider = new firebase.auth.TwitterAuthProvider();
      const credential = await firebase.auth().signInWithPopup(provider);
      const oauthToken = credential.credential.accessToken;
      const oauthTokenSecret = credential.credential.secret;

      const apiResult = await signInWithTwitterAPI(
        oauthToken,
        oauthTokenSecret
      );
      const { token, user } = apiResult;

      if (!user) {
        throw new Error("User ID not found");
      }

      setUser(user); // Set user in context
      setCookie("currentUser", JSON.stringify(user));

      navigateUser(user);
    } catch (error) {
      setError(error.message);
      console.error("Ошибка аутентификации через Twitter:", error);
    }
  };

  const signInWithGitHub = async () => {
    try {
      const provider = new firebase.auth.GithubAuthProvider();
      const credential = await firebase.auth().signInWithPopup(provider);
      const accessToken = credential.credential.accessToken;

      const apiResult = await signInWithGitHubAPI(accessToken);
      const { token, user } = apiResult;

      if (!user) {
        throw new Error("User ID not found");
      }

      setUser(user); // Set user in context
      setCookie("currentUser", JSON.stringify(user));

      navigateUser(user);
    } catch (error) {
      setError(error.message);
      console.error("Ошибка аутентификации через GitHub:", error);
    }
  };

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? cookieValue.pop() : null;
  };

  return (
    <div className="bg-lighter min-h-screen flex flex-col gap-3 justify-center items-center">
      <div className="max-w-md w-full bg-darker shadow-lg rounded-md p-6 flex flex-col gap-2 justify-center items-center">
      <h1 className="text-2xl">Увійти до аккаунту</h1>
      <br />
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent rounded-md py-2 px-4 mb-4 w-full"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent rounded-md py-2 px-4 mb-4 w-full"
        />
        <button
          onClick={signInWithEmail}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-2 w-full"
        >
          Війти за допомогою email
        </button>
        <h1>Або увійти за допомогою:</h1>
        <div className="flex flex-row gap-3 justify-center">
          <button
            onClick={signInWithGoogle}
            className="bg-gray-200 hover:bg-gray-300 font-bold py-2 px-2 rounded focus:outline-none flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="32"
              height="32"
              viewBox="0 0 48 48"
            >
              <path
                fill="#FFC107"
                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
              <path
                fill="#FF3D00"
                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
              ></path>
              <path
                fill="#4CAF50"
                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
              ></path>
              <path
                fill="#1976D2"
                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
            </svg>
          </button>
          <button
            onClick={signInWithGitHub}
            className="bg-gray-200 hover:bg-gray-300 font-bold py-2 px-2 rounded focus:outline-none flex items-center justify-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 50 50">
    <path d="M17.791,46.836C18.502,46.53,19,45.823,19,45v-5.4c0-0.197,0.016-0.402,0.041-0.61C19.027,38.994,19.014,38.997,19,39 c0,0-3,0-3.6,0c-1.5,0-2.8-0.6-3.4-1.8c-0.7-1.3-1-3.5-2.8-4.7C8.9,32.3,9.1,32,9.7,32c0.6,0.1,1.9,0.9,2.7,2c0.9,1.1,1.8,2,3.4,2 c2.487,0,3.82-0.125,4.622-0.555C21.356,34.056,22.649,33,24,33v-0.025c-5.668-0.182-9.289-2.066-10.975-4.975 c-3.665,0.042-6.856,0.405-8.677,0.707c-0.058-0.327-0.108-0.656-0.151-0.987c1.797-0.296,4.843-0.647,8.345-0.714 c-0.112-0.276-0.209-0.559-0.291-0.849c-3.511-0.178-6.541-0.039-8.187,0.097c-0.02-0.332-0.047-0.663-0.051-0.999 c1.649-0.135,4.597-0.27,8.018-0.111c-0.079-0.5-0.13-1.011-0.13-1.543c0-1.7,0.6-3.5,1.7-5c-0.5-1.7-1.2-5.3,0.2-6.6 c2.7,0,4.6,1.3,5.5,2.1C21,13.4,22.9,13,25,13s4,0.4,5.6,1.1c0.9-0.8,2.8-2.1,5.5-2.1c1.5,1.4,0.7,5,0.2,6.6c1.1,1.5,1.7,3.2,1.6,5 c0,0.484-0.045,0.951-0.11,1.409c3.499-0.172,6.527-0.034,8.204,0.102c-0.002,0.337-0.033,0.666-0.051,0.999 c-1.671-0.138-4.775-0.28-8.359-0.089c-0.089,0.336-0.197,0.663-0.325,0.98c3.546,0.046,6.665,0.389,8.548,0.689 c-0.043,0.332-0.093,0.661-0.151,0.987c-1.912-0.306-5.171-0.664-8.879-0.682C35.112,30.873,31.557,32.75,26,32.969V33 c2.6,0,5,3.9,5,6.6V45c0,0.823,0.498,1.53,1.209,1.836C41.37,43.804,48,35.164,48,25C48,12.318,37.683,2,25,2S2,12.318,2,25 C2,35.164,8.63,43.804,17.791,46.836z"></path>
</svg>
          </button>
          <button
            onClick={signInWithTwitter}
            className="bg-gray-200 hover:bg-gray-300 font-bold py-2 px-2 rounded focus:outline-none flex items-center justify-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 50 50">
<path d="M 50.0625 10.4375 C 48.214844 11.257813 46.234375 11.808594 44.152344 12.058594 C 46.277344 10.785156 47.910156 8.769531 48.675781 6.371094 C 46.691406 7.546875 44.484375 8.402344 42.144531 8.863281 C 40.269531 6.863281 37.597656 5.617188 34.640625 5.617188 C 28.960938 5.617188 24.355469 10.21875 24.355469 15.898438 C 24.355469 16.703125 24.449219 17.488281 24.625 18.242188 C 16.078125 17.8125 8.503906 13.71875 3.429688 7.496094 C 2.542969 9.019531 2.039063 10.785156 2.039063 12.667969 C 2.039063 16.234375 3.851563 19.382813 6.613281 21.230469 C 4.925781 21.175781 3.339844 20.710938 1.953125 19.941406 C 1.953125 19.984375 1.953125 20.027344 1.953125 20.070313 C 1.953125 25.054688 5.5 29.207031 10.199219 30.15625 C 9.339844 30.390625 8.429688 30.515625 7.492188 30.515625 C 6.828125 30.515625 6.183594 30.453125 5.554688 30.328125 C 6.867188 34.410156 10.664063 37.390625 15.160156 37.472656 C 11.644531 40.230469 7.210938 41.871094 2.390625 41.871094 C 1.558594 41.871094 0.742188 41.824219 -0.0585938 41.726563 C 4.488281 44.648438 9.894531 46.347656 15.703125 46.347656 C 34.617188 46.347656 44.960938 30.679688 44.960938 17.09375 C 44.960938 16.648438 44.949219 16.199219 44.933594 15.761719 C 46.941406 14.3125 48.683594 12.5 50.0625 10.4375 Z"></path>
</svg>
          </button>
        </div>
        <NavLink
              to="/signin"
              className="flex items-center p-2 text-gray-400 rounded-md group"
            >
              Нема аккаунту? Створити новий...
        </NavLink>
      </div>
    </div>
  );
};

export default LoginPage;
