import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const DoughnutChart = ({ subjectGrades }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (Object.keys(subjectGrades).length === 0) return;

    // Calculate the total number of grades
    const totalGrades = Object.values(subjectGrades).reduce((total, subject) => total + subject.grades.length, 0);

    const options = {
      series: Object.values(subjectGrades).map(subject => (subject.grades.length / totalGrades) * 100), // Calculate percentage for each subject
      colors: [
        "#fbbf24", 
        "#16BDCA", 
        "#2563eb", 
        "#E74694", 
        "#34D399", 
        "#6366F1", 
        "#F87171", 
        "#60A5FA", 
        "#D97706", 
        "#4ADE80",
        "#FFD166",
        "#6A4C93",
        "#8338EC",
        "#FF6F61",
        "#FFD166"
      ],
      
      chart: {
        height: 320,
        width: 220,
        type: "donut",
        background: '#302F37', // Dark background color
        foreColor: '#D1D5DB', // Light text color
        fontFamily: 'Jura',
      },
      stroke: {
        colors: ["transparent"],
        lineCap: "",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                offsetY: 20,
              },
              total: {
                showAlways: true,
                show: true,
                label: "Загалом",
                formatter: function (w) {
                  return totalGrades
                },
              },
              value: {
                show: true,
                offsetY: -20,
                formatter: function (value) {
                  return value
                },
              },
            },
            size: "80%",
          },
        },
      },
      grid: {
        padding: {
          top: -2,
        },
      },
      labels: Object.keys(subjectGrades), // Subject names as labels
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.round(value) + " %"
          },
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            return value  + "k"
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
    };

    if (chartRef.current) {
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();
      return () => chart.destroy();
    }
  }, [subjectGrades]);

  return (
    <div className="bg-darker w-full rounded-md p-4 flex justify-center">
      <div ref={chartRef} />
    </div>
  );
};

export default DoughnutChart;
