import React from 'react';

const StudentCard = ({ student }) => {
  const { name, grades } = student;

  // Функция для вычисления средней оценки
  const calculateAvgGrade = () => {
    if (!grades || grades.length === 0) {
      return 'N/A';
    }

    // Вычисляем сумму всех оценок
    const totalGrade = grades.reduce((sum, grade) => sum + grade.grade, 0);
    // Вычисляем среднее значение
    const avgGrade = totalGrade / grades.length;
    // Округляем до ближайшего целого
    return Math.round(avgGrade);
  };

  return (
    <div className="w-44 p-4 bg-darker rounded-md text-white flex justify-center items-center" style={{ height: '200px' }}> {/* Увеличение высоты */}
      <div className="flex flex-col items-center">
        <div className="w-16 h-16 bg-gray-600 rounded-full flex justify-center items-center text-gray-400 font-bold text-lg mb-2 mt-2">
          {name[0]}
        </div>
        <div>
          <h1 className="text-center font-semibold text-white">{name}</h1>
          <div className="flex justify-center text-lg font-semibold text-gray-400"> {/* Обновлен стиль */}
            <h2>Ср/бал: {calculateAvgGrade()}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;



