import React, { useState, useEffect } from "react";
import {
  getAllStudents,
  getAllSubjects,
  getGroupById,
  getGroups,
} from "../services/api";
import { default as BarChart } from "./BarChart";
import { default as TopStudentsList } from "./TopStudentsList";
import SubjectBarChart from "./SubjectBarChart";
import GroupCard from "../Global/GroupCard";
import DoughnutChart from "./DoughnutChart";
import SubjectCard from "../Global/SubjectCard";

const StudentsTable = () => {
  const [students, setStudents] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [studentSubjectStats, setStudentSubjectStats] = useState({});
  const [gradesStats, setGradesStats] = useState({});
  const [groups, setGroups] = useState([]);
  const [subjectGrades, setSubjectGrades] = useState({}); // State to store grades for each subject

  useEffect(() => {
    const fetchData = async () => {
      try {
        const studentsData = await getAllStudents();
        const subjectsData = await getAllSubjects();

        const studentsWithSubjects = await Promise.all(
          studentsData.map(async (student) => {
            let groupName = "Unknown Group";
            if (student.group) {
              const group = await getGroupById(student.group);
              groupName = group ? group.name : "Unknown Group";
            }
            const gradesWithSubjects = await Promise.all(
              student.grades.map(async (gradeObj) => {
                const subject = subjectsData.find(
                  (subject) => subject._id === gradeObj.subject
                );
                return {
                  ...gradeObj,
                  subject: subject ? subject.name : "Unknown Subject",
                };
              })
            );
            return {
              ...student,
              group: groupName,
              grades: gradesWithSubjects,
            };
          })
        );

        setStudents(studentsWithSubjects);
        setSubjects(subjectsData);
        calculateGradesStats(studentsWithSubjects);
        calculateSubjectGrades(studentsWithSubjects, subjectsData); // Call the new function to calculate grades
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const allGroups = await getGroups();
        setGroups(allGroups);
      } catch (error) {
        console.error(error);
      }
    };

    fetchGroups();
  }, []);

  const calculateGradesStats = (students) => {
    const groupStatistics = {};

    students.forEach((student) => {
      if (!groupStatistics[student.group]) {
        groupStatistics[student.group] = {
          min: Number.MAX_VALUE,
          max: Number.MIN_VALUE,
          total: 0,
          count: 0,
        };
      }

      const groupStats = groupStatistics[student.group];

      student.grades.forEach((gradeObj) => {
        const grade = gradeObj.grade;
        if (grade < groupStats.min) groupStats.min = grade;
        if (grade > groupStats.max) groupStats.max = grade;
        groupStats.total += grade;
        groupStats.count++;
      });
    });

    Object.keys(groupStatistics).forEach((group) => {
      const groupStats = groupStatistics[group];
      const avgGrade = groupStats.count
        ? (groupStats.total / groupStats.count).toFixed(2)
        : 0;
      groupStatistics[group] = {
        min: groupStats.min === Number.MAX_VALUE ? 0 : groupStats.min,
        max: groupStats.max === Number.MIN_VALUE ? 0 : groupStats.max,
        avg: avgGrade,
      };
    });

    setGradesStats(groupStatistics);
  };

  const calculateSubjectGrades = (students, subjects) => {
    const subjectGrades = {};

    subjects.forEach((subject) => {
      subjectGrades[subject.name] = {
        grades: [],
      };
    });

    students.forEach((student) => {
      student.grades.forEach((gradeObj) => {
        if (gradeObj.subject in subjectGrades) {
          subjectGrades[gradeObj.subject].grades.push(gradeObj.grade);
        }
      });
    });
    console.log(subjectGrades);
    setSubjectGrades(subjectGrades);
  };

  const calculateStudentSubjectStats = (subjectName) => {
    if (!subjectName) return;

    const studentStats = {};
    students.forEach((student) => {
      student.grades.forEach((gradeObj) => {
        if (gradeObj.subject === subjectName) {
          const grade = gradeObj.grade;
          if (!studentStats[student._id]) {
            studentStats[student._id] = {
              min: Number.MAX_VALUE,
              max: Number.MIN_VALUE,
              total: 0,
              count: 0,
            };
          }
          const studentData = studentStats[student._id];
          if (grade < studentData.min) studentData.min = grade;
          if (grade > studentData.max) studentData.max = grade;
          studentData.total += grade;
          studentData.count++;
        }
      });
    });

    Object.keys(studentStats).forEach((studentId) => {
      const studentData = studentStats[studentId];
      const avgGrade = studentData.count
        ? (studentData.total / studentData.count).toFixed(2)
        : 0;
      studentData.avg = avgGrade;
    });

    setStudentSubjectStats(studentStats);
  };

  const handleSubjectChange = (subjectName) => {
    setSelectedSubject(subjectName);
    if (subjectName === "") {
      setStudentSubjectStats({});
    } else {
      calculateStudentSubjectStats(subjectName);
    }
  };

  return (
    <div className="p-6 bg-lighter text-white">
      <div className="flex gap-4">
        <div className="bg-darker-3 w-fit p-6 rounded-md flex flex-col justify-center">
          <div className="flex flex-row gap-2">
            <svg
              width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM13.75 10C13.75 10.4142 14.0858 10.75 14.5 10.75H15.1893L13.1768 12.7626C13.0791 12.8602 12.9209 12.8602 12.8232 12.7626L11.2374 11.1768C10.554 10.4934 9.44598 10.4934 8.76256 11.1768L6.46967 13.4697C6.17678 13.7626 6.17678 14.2374 6.46967 14.5303C6.76256 14.8232 7.23744 14.8232 7.53033 14.5303L9.82322 12.2374C9.92085 12.1398 10.0791 12.1398 10.1768 12.2374L11.7626 13.8232C12.446 14.5066 13.554 14.5066 14.2374 13.8232L16.25 11.8107V12.5C16.25 12.9142 16.5858 13.25 17 13.25C17.4142 13.25 17.75 12.9142 17.75 12.5V10C17.75 9.58579 17.4142 9.25 17 9.25H14.5C14.0858 9.25 13.75 9.58579 13.75 10Z"
                fill="white"
              />
            </svg>
            <h1 className="text-2xl font-bold mb-4">Розподіл оцінок</h1>
          </div>

          <div className="mb-4">
            <div className="flex flex-wrap overflow-y-auto max-h-16">
              <button
                className={`m-2 px-4 py-2 rounded-full focus:outline-none ${
                  selectedSubject === "Загальне"
                    ? "bg-orange-500 text-white"
                    : "bg-lighter-2 text-gray-300"
                }`}
                onClick={() => handleSubjectChange("Загальне")}
              >
                Загальне
              </button>
              {subjects.map((subject) => (
                <button
                  key={subject._id}
                  className={`m-2 px-4 py-2 rounded-full focus:outline-none ${
                    selectedSubject === subject.name
                      ? "bg-orange-500 text-white"
                      : "bg-lighter-2 text-gray-300"
                  }`}
                  onClick={() => handleSubjectChange(subject.name)}
                >
                  {subject.name}
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-row gap-5">
            {selectedSubject === "" && <div></div>}{" "}
            {/* Пустой div, если ничего не выбрано */}
            {selectedSubject === "Загальне" && (
              <BarChart
                groupData={Object.entries(gradesStats).map(([name, stats]) => ({
                  name,
                  ...stats,
                }))}
              />
            )}{" "}
            {/* Показываем BarChart, если выбрано "Загальне" */}
            {selectedSubject !== "" && selectedSubject !== "Загальне" && (
              <SubjectBarChart
                studentSubjectStats={studentSubjectStats}
                students={students}
              />
            )}{" "}
            {/* Показываем SubjectBarChart, если выбран конкретный предмет */}
          </div>
        </div>

        <div className="flex flew-row gap-2 bg-darker-3 w-fit p-6 rounded-md flex flex-col justify-center">
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM2 17.25C2 14.92 6.66 13.75 9 13.75C11.34 13.75 16 14.92 16 17.25V19H2V17.25ZM9 15.75C7.21 15.75 5.18 16.42 4.34 17H13.66C12.82 16.42 10.79 15.75 9 15.75ZM10.5 8.5C10.5 7.67 9.83 7 9 7C8.17 7 7.5 7.67 7.5 8.5C7.5 9.33 8.17 10 9 10C9.83 10 10.5 9.33 10.5 8.5ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM18.5 8.5C18.5 10.43 16.93 12 15 12C14.46 12 13.96 11.87 13.5 11.65C14.13 10.76 14.5 9.67 14.5 8.5C14.5 7.33 14.13 6.24 13.5 5.35C13.96 5.13 14.46 5 15 5C16.93 5 18.5 6.57 18.5 8.5Z"
                  fill="white"
                />
              </svg>
              <h1 className="text-2xl font-bold mb-4">Групи</h1>
            </div>
            <div className="overflow-y-auto max-h-96 flex flex-col gap-3">
              {groups.map((group) => (
                <GroupCard key={group._id} group={group} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col w-full gap-2 bg-darker-3 p-6  rounded-md justify-center">
            <div className="flex flew-row gap-3">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 4.84969V16.7397C22 17.7097 21.21 18.5997 20.24 18.7197L19.93 18.7597C18.29 18.9797 15.98 19.6597 14.12 20.4397C13.47 20.7097 12.75 20.2197 12.75 19.5097V5.59969C12.75 5.22969 12.96 4.88969 13.29 4.70969C15.12 3.71969 17.89 2.83969 19.77 2.67969H19.83C21.03 2.67969 22 3.64969 22 4.84969Z"
                  fill="white"
                />
                <path
                  d="M10.7083 4.70969C8.87828 3.71969 6.10828 2.83969 4.22828 2.67969H4.15828C2.95828 2.67969 1.98828 3.64969 1.98828 4.84969V16.7397C1.98828 17.7097 2.77828 18.5997 3.74828 18.7197L4.05828 18.7597C5.69828 18.9797 8.00828 19.6597 9.86828 20.4397C10.5183 20.7097 11.2383 20.2197 11.2383 19.5097V5.59969C11.2383 5.21969 11.0383 4.88969 10.7083 4.70969ZM4.99828 7.73969H7.24828C7.65828 7.73969 7.99828 8.07969 7.99828 8.48969C7.99828 8.90969 7.65828 9.23969 7.24828 9.23969H4.99828C4.58828 9.23969 4.24828 8.90969 4.24828 8.48969C4.24828 8.07969 4.58828 7.73969 4.99828 7.73969ZM7.99828 12.2397H4.99828C4.58828 12.2397 4.24828 11.9097 4.24828 11.4897C4.24828 11.0797 4.58828 10.7397 4.99828 10.7397H7.99828C8.40828 10.7397 8.74828 11.0797 8.74828 11.4897C8.74828 11.9097 8.40828 12.2397 7.99828 12.2397Z"
                  fill="white"
                />
              </svg>
              <h1 className="text-xl font-bold">Предмети:</h1>
            </div>

            <div className="flex flex-row flex-wrap gap-3 overflow-y-auto max-h-20">
              {subjects.map((subject) => (
                <SubjectCard key={subject._id} subject={subject} />
              ))}
            </div>
          </div>
          <div className="flex flew-row gap-2 bg-darker-3 w-fit p-6 rounded-md flex flex-col justify-center">
            <div className="flex flew-row gap-3">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 12V8C2 5.17157 2 3.75736 2.87868 2.87868C3.75736 2 5.17157 2 8 2H16C18.8284 2 20.2426 2 21.1213 2.87868C22 3.75736 22 5.17157 22 8V12C22 14.8284 22 16.2426 21.1213 17.1213C20.645 17.5977 20.0113 17.8158 19.0802 17.9156C19.0062 17.8157 18.9362 17.737 18.8862 17.6821C18.7701 17.5544 18.6228 17.4092 18.48 17.2684L16.987 15.7962L16.4357 15.2377C16.0733 13.1151 14.2255 11.5 12.0001 11.5C9.77461 11.5 7.9268 13.1151 7.56446 15.2377L7.01311 15.7962L5.5202 17.2683C5.37739 17.4091 5.23008 17.5543 5.11392 17.6821C5.06395 17.737 4.9939 17.8157 4.9199 17.9156C3.98877 17.8158 3.35503 17.5977 2.87868 17.1213C2 16.2426 2 14.8284 2 12ZM9 5.25C8.58579 5.25 8.25 5.58579 8.25 6C8.25 6.41421 8.58579 6.75 9 6.75H15C15.4142 6.75 15.75 6.41421 15.75 6C15.75 5.58579 15.4142 5.25 15 5.25H9ZM6.25 9.5C6.25 9.08579 6.58579 8.75 7 8.75H17C17.4142 8.75 17.75 9.08579 17.75 9.5C17.75 9.91421 17.4142 10.25 17 10.25H7C6.58579 10.25 6.25 9.91421 6.25 9.5Z"
                  fill="white"
                />
                <path
                  d="M15 16C15 17.6569 13.6569 19 12 19C10.3431 19 9 17.6569 9 16C9 14.3431 10.3431 13 12 13C13.6569 13 15 14.3431 15 16Z"
                  fill="white"
                />
                <path
                  d="M9.00111 15.9174C9.00037 15.9449 9 15.9724 9 16C9 17.6569 10.3431 19 12 19C13.6569 19 15 17.6569 15 16C15 15.9724 14.9996 15.9449 14.9989 15.9174C14.9551 14.2987 13.6292 13 12 13C10.4467 13 9.16912 14.1805 9.01549 15.6933C9.00798 15.7672 9.00315 15.842 9.00111 15.9174Z"
                  fill="white"
                />
                <path
                  d="M7.6757 17.2494L6.59523 18.3148C6.27116 18.6344 6.10913 18.7942 6.05306 18.9295C5.92529 19.2378 6.03463 19.5799 6.31283 19.7421C6.43492 19.8132 6.65512 19.8354 7.09553 19.8798C7.3442 19.9048 7.46853 19.9173 7.57266 19.9554C7.80579 20.0405 7.98715 20.2193 8.07345 20.4492C8.112 20.5519 8.1247 20.6745 8.1501 20.9197C8.19509 21.354 8.21758 21.5711 8.28977 21.6915C8.45425 21.9659 8.80111 22.0737 9.1138 21.9477C9.25102 21.8924 9.41306 21.7326 9.73713 21.413L10.8175 20.343C9.30505 19.9322 8.10917 18.7524 7.6757 17.2494Z"
                  fill="white"
                />
                <path
                  d="M13.1825 20.343L14.2629 21.413C14.5869 21.7326 14.749 21.8924 14.8862 21.9477C15.1989 22.0737 15.5457 21.9659 15.7102 21.6915C15.7824 21.5711 15.8049 21.354 15.8499 20.9197C15.8753 20.6745 15.888 20.5519 15.9265 20.4492C16.0129 20.2193 16.1942 20.0405 16.4273 19.9554C16.5315 19.9173 16.6558 19.9048 16.9045 19.8798C17.3449 19.8354 17.5651 19.8132 17.6872 19.7421C17.9654 19.5799 18.0747 19.2378 17.9469 18.9295C17.8909 18.7942 17.7288 18.6344 17.4048 18.3148L16.3243 17.2494C15.8908 18.7524 14.6949 19.9322 13.1825 20.343Z"
                  fill="white"
                />
              </svg>
              <h1 className="text-xl font-bold">Оцінки:</h1>
            </div>
            <DoughnutChart subjectGrades={subjectGrades} />
          </div>
        </div>
      </div>
      <br />
      <div className="flex flew-row gap-2 bg-darker-3 w-fit p-6 rounded-md flex flex-col justify-center">
        <TopStudentsList students={students} gradesStats={gradesStats} />
      </div>
    </div>
  );
};

export default StudentsTable;
