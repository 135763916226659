// services/api.js

import axios from 'axios';

// Базовый URL для вашего сервера
const baseURL = '/api';

// Создание экземпляра Axios с базовым URL
const api = axios.create({
  baseURL,
});

// Функция для получения списка групп
export const getGroups = async () => {
  try {
    const response = await api.get('/groups');
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGroupById = async (groupId) => {
  try {
    const response = await api.get(`/groups/${groupId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGroup = async (groupId) => {
    try {
      await api.delete(`/groups/${groupId}`);
    } catch (error) {
      throw error;
    }
  };

export const addGroup = async (groupName) => {
    try {
      const response = await api.post(`/groups`, { name: groupName });
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const editGroup = async (groupId, newName) => {
    try {
      const response = await api.put(`/groups/${groupId}`, { name: newName });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getStudentsByGroupId = async (groupId) => {
    try {
      const response = await api.get(`/students/groups/${groupId}/students`);
      const studentsWithGroup = response.data.map(student => ({
        ...student,
        group: { name: student.groupName, _id: groupId } // добавляем данные о группе
      }));
      return studentsWithGroup;
    } catch (error) {
      throw new Error(`Error fetching students: ${error.message}`);
    }
  };
  
  export const addStudent = async (groupId, name, email) => {
    try {
      const response = await api.post(`/students/${groupId}/students`, {
        name,
        email,
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error adding student: ${error.message}`);
    }
  };
  // Function to edit a student's name
export const editStudent = async (studentId, newName) => {
  try {
    const response = await api.put(`/students/${studentId}`, { name: newName });
    return response.data;
  } catch (error) {
    throw new Error(`Error editing student: ${error.message}`);
  }
};

// Function to delete a student by ID
export const deleteStudent = async (studentId) => {
  try {
    await api.delete(`/students/${studentId}`);
  } catch (error) {
    throw error;
  }
};

export const changeStudentGroup = async (studentId, groupId) => {
  try {
    const response = await api.put(`/students/${studentId}/changeGroup`, {groupId});
    return response.data;
  } catch (error) {
    throw error;
  }
};
// Функция для получения всех предметов
export const getAllSubjects = async () => {
  try {
    const response = await api.get(`/subjects`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Функция для получения всех предметов
export const getSubjects = async () => {
  try {
    const response = await api.get(`/subjects`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Функция для удаления предмета по его ID
export const deleteSubject = async (subjectId) => {
  try {
    await api.delete(`/subjects/${subjectId}`);
  } catch (error) {
    throw error;
  }
};

// Функция для добавления нового предмета
export const addSubject = async (subjectName) => {
  try {
    const response = await api.post(`/subjects`, { name: subjectName });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Функция для редактирования предмета
export const editSubjectName = async (subjectId, newName) => {
  try {
    const response = await api.put(`/subjects/${subjectId}`, { name: newName });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const editSubjectStatus = async (subjectId, newStatus) => {
  try {
    const response = await api.put(`/subjects/${subjectId}`, { acceptsGrades: newStatus });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const editSubjectPublic = async (subjectId, newStatus) => {
  try {
    const response = await api.put(`/subjects/${subjectId}`, { publicRating: newStatus });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// Функция для получения всех оценок по ID предмета
export const getGradesBySubjectId = async (subjectId) => {
  try {
    const response = await api.get(`/grades/subjects/${subjectId}/grades`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addGradeForStudent = async (studentId, subjectId, Grade) => {
  try {
    const response = await api.post(`students/${studentId}/grades`, { subject: subjectId, grade: Grade });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteGradeForStudent = async (studentId, idGrade) => {
  try {
    const response = await api.delete(`students/${studentId}/grades/${idGrade}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getStudentsByGrade = async (subjectId, grade) => {
  try {
    const response = await api.get(`/students/grade/${subjectId}/${grade}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
// Функция для получения всех предметов
export const getAllStudents = async () => {
  try {
    const response = await api.get(`/students`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getUserRole = async (userId) => {
  try {
    const response = await api.get(`/user/${userId}/role`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const loginWithEmail = async (email, password) => {
  try {
    const response = await api.post('/auth/login', { email, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// Функция для аутентификации через Google
export const signInWithGoogle = async (idToken) => {
  try {
    const response = await api.post('/auth/google_signin', {idToken});
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const signInWithGitHub = async (accessToken) => {
  try {
    const response = await api.post('/auth/github_signin', {accessToken});
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const signInWithTwitter = async ( oauthToken, oauthTokenSecret) => {
  try {
    const response = await api.post('/auth/twitter_signin', {oauthToken, oauthTokenSecret});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const authInWithGoogle = async (idToken, role) => {
  try {
    const response = await api.post('/auth/google_auth', {idToken, role});
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const authInWithGitHub = async (accessToken, role) => {
  try {
    const response = await api.post('/auth/github_auth', {accessToken, role});
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const authInWithTwitter = async ( oauthToken, oauthTokenSecret, role) => {
  try {
    const response = await api.post('/auth/twitter_auth', {oauthToken, oauthTokenSecret, role});
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const registerWithEmail = async (email, password, nickname, role) => {
  try {
    const response = await api.post('/auth/register', {email, password, nickname, role});
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const logout = async () => {
  try {
    const response = await api.post('/auth/logout');
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const editNickname = async (userId, newNick) => {
  try {
    const response = await api.put(`/user/${userId}/nickname`, { newNickname:newNick });
    return response.data;
  } catch (error) {
    throw error;
  }
};