import React, { useState, useEffect } from 'react';
import { getAllStudents, getAllSubjects } from '../services/api'; // Імпортуємо функції API для отримання інформації про студентів і предметів

const GradesPage = () => {
  const [subjectId, setSubjectId] = useState('');
  const [students, setStudents] = useState([]);
  const [subjects, setSubjects] = useState([]); // Зберігаємо список предметів

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const studentData = await getAllStudents(); // Отримуємо список всіх студентів з сервера
        setStudents(studentData);
        
        const subjectData = await getAllSubjects(); // Отримуємо список всіх предметів з сервера
        setSubjects(subjectData);
      } catch (error) {
        console.error('Помилка отримання даних:', error);
      }
    };

    fetchStudents();
  }, []); 

  const handleSelectChange = (event) => {
    const selectedSubjectId = event.target.value;
    setSubjectId(selectedSubjectId);
  };

  const getGradesForSubject = (student) => {
    if (!subjectId) return []; // Якщо предмет не вибраний, повертаємо порожній масив оцінок
    return student.grades.filter(grade => grade.subject === subjectId);
  };

  return (
    <div className="p-6 bg-lighter text-white">
      <h1 className="text-2xl font-bold mb-4">Таблиця оцінок</h1>
      <div className="mb-4">
        <label htmlFor="subject-select" className="mr-2 text-gray-300">Оберіть предмет зі списку:</label>
        <select id="subject-select" value={subjectId} onChange={handleSelectChange} className="border-none h-1/4 rounded bg-darker text-gray-300">
          <option value="">Предмет...</option>
          {/* Мапимо список предметів для випадаючого списку */}
          {subjects.map(subject => (
            <option key={subject._id} value={subject._id}>{subject.name}</option>
          ))}
        </select>
      </div>
      <table className="w-1/2">
        <thead>
          <tr className="bg-darker text-white">
            <th className="py-2 px-4 bg-darker-3">Ім'я</th>
            <th className="py-2 px-4 bg-darker-3">Оцінки</th>
          </tr>
        </thead>
        <tbody className="text-gray-300">
          {/* Мапимо студентів для таблиці */}
          {students.map(student => (
            <tr key={student._id} className="border-b border-gray-700">
              <td className="py-2 px-4 bg-darker">{student.name}</td>
              <td className="py-2 px-4 bg-darker">
                {/* Виводимо оцінки для обраного предмета */}
                {(() => {
                  const grades = getGradesForSubject(student);
                  if (grades.length === 0) {
                    return <div>Оцінок поки немає.</div>;
                  } else {
                    return <div>{grades.map(grade => grade.grade).join(', ')}</div>;
                  }
                })()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GradesPage;
